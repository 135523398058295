.share-form {
  position: relative;
  background-color: #596F40;
}

.share-input {
  background: none;
  border: none;
  border-bottom: 1px solid #c2c2c2;
  outline: none;
  appearance: none;
  border-radius: 0;
  color: #e8e8e8;
  font-weight: bold;
  letter-spacing: 0.1em;
}

.share-input::placeholder {
  color: #e8e8e8;
  text-transform: uppercase;
}

.share-control {
  cursor: pointer;
  transition-duration: 0.2s;
  width: 3em;
  height: 3em;
  padding: 0.1em;
  /* border: 1px solid #C7A791; */
  /* background-color: white; */
}

.share-control:hover {
  opacity: 0.6;
}
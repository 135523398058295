.fav-swiper {
  width: 100%;
  height: 100%;
}

.fav-swiper .swiper-slide {
  /* border: orange solid 1px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-bullet {
  background-color: #747474;
  width: 0.9em;
  height: 0.9em;
  border-radius: 0;
  margin: 0 0.7em !important;
}

.swiper-pagination-bullet-active {
  background-color: #636363;
}
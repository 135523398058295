.carousel-swiper {
    width: 100%;
    height: 100%;
}

.carousel-swiper .swiper-wrapper {
    align-items: center;
}

.carousel-swiper .swiper-pagination{
    position: fixed;
    bottom: 2em;
}

.carousel-swiper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
}

.carousel-swiper .swiper-button-next {
    position: fixed;
    top: auto;
    bottom: 2em;
    right: 3%;
    background-image: url("assets/images/icons/arrow.svg");
    background-size: 100% 100%;
    background-position: center;
    width: 6vw;
    height: 3vw;
}

.carousel-swiper .swiper-button-next:after {
    content: "";
    display: none;
}

.carousel-swiper .swiper-button-prev {
    position: fixed;
    top: auto;
    bottom: 2em;
    left: 3%;
    background-image: url("assets/images/icons/arrow.svg");
    background-size: 100% 100%;
    background-position: center;
    width: 6vw;
    height: 3vw;
    transform: rotateZ(180deg);
}

.carousel-swiper .swiper-button-prev:after {
    content: "";
    display: none;
}
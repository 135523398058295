.grid-swiper {
    width: calc(100% - 8em);
    height: calc(100% - 15em);
}

.grid-swiper .swiper-wrapper {
    align-items: center;
}

.grid-swiper .swiper-pagination{
    position: fixed;
    bottom: 2em;
}

.grid-swiper .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
}

.grid-swiper .swiper-button-next {
    position: fixed;
    top: auto;
    bottom: 2em;
    right: 3%;
    background-image: url("assets/images/icons/arrow.svg");
    background-size: 100% 100%;
    background-position: center;
    width: 6vw;
    height: 3vw;
}

.grid-swiper .swiper-button-next:after {
    content: "";
    display: none;
}

.grid-swiper .swiper-button-prev {
    position: fixed;
    top: auto;
    bottom: 2em;
    left: 3%;
    background-image: url("assets/images/icons/arrow.svg");
    background-size: 100% 100%;
    background-position: center;
    width: 6vw;
    height: 3vw;
    transform: rotateZ(180deg);
}

.grid-swiper .swiper-button-prev:after {
    content: "";
    display: none;
}
.hg-theme-default {
    position: absolute;
    z-index: 999;
    bottom: 0;
    border-radius: 0;
    background: #596f40;
    backdrop-filter: blur(10px);
    font-family: inherit;
    transform: translateY(-3vw);
    transition: all 0.5s ease;
    width: 55vw;
}

.hg-theme-default.hidden {
    bottom: -300px;
    transform: translateY(100%);
    transition: all 0.5s ease;
}

.hg-theme-default .hg-row {
    height: 50px;
}

.hg-theme-default .hg-button {
    height: 50px;
    background: #ffffff4d;
    color: white;
}

.hg-button-hide {
    max-width: 145px;
}

@media screen and (max-width: 1380px) {
    .hg-theme-default {
        width: 100%;
        bottom: -5vw;
        transform: translateY(-5vw);
    }
}

@media screen and (min-width: 1980px) {
    .hg-theme-default {
        width: 40vw;
        bottom: -5vw;
        transform: translateY(-10vw);
    }
}
.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.swiper-button-next {
    top: auto;
    bottom: 4%;
    right: 3%;
    background-image: url("assets/images/icons/arrow.svg");
    background-size: 100% 100%;
    background-position: center;
    width: 6vw;
    height: 3vw;
}

.swiper-button-next:after {
    content: "";
    display: none;
}

.swiper-button-prev {
    top: auto;
    bottom: 4%;
    left: 3%;
    background-image: url("assets/images/icons/arrow.svg");
    background-size: 100% 100%;
    background-position: center;
    width: 6vw;
    height: 3vw;
    transform: rotateZ(180deg);
}

.swiper-button-prev:after {
    content: "";
    display: none;
}